@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Regular.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Italic.ttf') format('truetype');
  font-style: italic;
}

body {
  font-family: 'Inter', sans-serif;
}
